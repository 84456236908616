import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, InputLabel,
  Tooltip, IconButton, CircularProgress
} from '@mui/material';
import RichTextEditorModal from '../../components/common/modal/richTextEditorModal';
import CategorySelection from '../../components/common/categorySelect';
import styles from './multiselect.module.css';
import 'react-quill/dist/quill.snow.css';
import Modal from "../../components/common/modal/Modal";
import noImage from '../../assets/images/noImage.png';
import api from '../../utils/api';
import ClearIcon from '@mui/icons-material/Clear';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoIcon from '@mui/icons-material/Info';
import { useNotification } from '../../contexts/NotificationContext';
import { priceRegex, calculatePriceAndPercentage } from '../../utils/pricing';
import CustomSwitch from '../../components/common/toggle/toggle';
import PriceTypeSelector from '../../components/common/radioGroup/PriceTypeRadioGroup';

const MultiSelectDialog = ({ open, handleClose, selectedProducts }) => {
  const [editedProducts, setEditedProducts] = useState([]);
  const [activeModalId, setActiveModalId] = useState(null);
  const [confirmationModal, setconfirmationModal] = useState(false);
  const [toggleState, setToggleState] = useState({}); // Manage toggles for each field
  const [modalContent, setModalContent] = useState({ index: null, description: '', title: 'Edit Description', });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [descriptionExpanded, setDescriptionExpanded] = useState({});
  const [loading, setLoading] = useState(false);

  const tooltipThreshold = 20;
  const { showNotification } = useNotification();

  useEffect(() => {
    if (open) {
      setEditedProducts(selectedProducts);

      const initialToggleState = {};
      selectedProducts.forEach((product, index) => {
        initialToggleState[`${index}-price`] = product.price !== product.ebay_sync_data.price;
        initialToggleState[`${index}-quantity`] = product.quantity !== product.ebay_sync_data.quantity;
        initialToggleState[`${index}-name`] = product.name !== product.ebay_sync_data.name;
        initialToggleState[`${index}-is_sync`] = product.ebay_sync_data.is_sync;
        initialToggleState[`${index}-description`] = product.description !== product.ebay_sync_data.description;
        initialToggleState[`${index}-shippingCost`] = product.ebay_sync_data.shippingCost !== '';
        initialToggleState[`${index}-additionalShippingCost`] = product.ebay_sync_data.additionalShippingCost !== '';
        
        // Initialize shipping cost fields if they don't exist
        if (!product.ebay_sync_data.shippingCost) {
          product.ebay_sync_data.shippingCost = '';
        }
        if (!product.ebay_sync_data.additionalShippingCost) {
          product.ebay_sync_data.additionalShippingCost = '';
        }

        product.ebay_sync_data.combinations.forEach((combination, combinationIndex) => {
          initialToggleState[`${index}-${combinationIndex}-name`] = combination.name !== combination.ebay_sync_data.name;
          initialToggleState[`${index}-${combinationIndex}-quantity`] = combination.quantity !== combination.ebay_sync_data.quantity;
          initialToggleState[`${index}-${combinationIndex}-is_sync`] = combination.ebay_sync_data.is_sync;
        });
      });

      setToggleState(initialToggleState);
    }
  }, [open, selectedProducts]);


  useEffect(() => {
    if (open) {
      setEditedProducts(selectedProducts);
    }
  }, [open, selectedProducts]);

  const handleToggleChange = (productIndex, fieldName) => {
    setToggleState((prevState) => ({
      ...prevState,
      [`${productIndex}-${fieldName}`]: !prevState[`${productIndex}-${fieldName}`]
    }));
    setEditedProducts((prevProducts) => {
      const updatedProducts = [...prevProducts];
      if (fieldName === 'is_sync') {
        updatedProducts[productIndex] = {
          ...updatedProducts[productIndex],
          is_sync: !prevProducts[productIndex].ebay_sync_data.is_sync,
          ebay_sync_data: {
            ...updatedProducts[productIndex].ebay_sync_data,
            [fieldName]: !prevProducts[productIndex].ebay_sync_data.is_sync
          }
        };
      } else {
        updatedProducts[productIndex] = {
          ...updatedProducts[productIndex],
          ebay_sync_data: {
            ...updatedProducts[productIndex].ebay_sync_data,
            [fieldName]: prevProducts[productIndex][fieldName]
          }
        };
      }

      return updatedProducts;
    });


  };

  const handleToggleCombinationChange = (productIndex, combinationIndex, event) => {
    const { name, value } = event.target;

    setToggleState((prevState) => ({
      ...prevState,
      [`${productIndex}-${combinationIndex}-${name}`]: !prevState[`${productIndex}-${combinationIndex}-${name}`]
    }));
    if (name === 'is_sync') {
      setEditedProducts((prevProducts) =>
        prevProducts.map((product, i) =>
          i === productIndex
            ? {
              ...product,
              ebay_sync_data: {
                ...product.ebay_sync_data,
                combinations: product.ebay_sync_data.combinations.map((combination, j) => {
                  return j === combinationIndex
                    ? {
                      ...combination,
                      ebay_sync_data: {
                        ...combination.ebay_sync_data,
                        [name]: !combination.ebay_sync_data.is_sync
                      },
                    }
                    : combination
                }),
              },
            }
            : product
        )
      );
    } else {
      setEditedProducts((prevProducts) =>
        prevProducts.map((product, i) =>
          i === productIndex
            ? {
              ...product,
              ebay_sync_data: {
                ...product.ebay_sync_data,
                combinations: product.ebay_sync_data.combinations.map((combination, j) => {
                  return j === combinationIndex
                    ? {
                      ...combination,
                      ebay_sync_data: {
                        ...combination.ebay_sync_data,
                        [name]: combination[name]
                      },
                    }
                    : combination
                }),
              },
            }
            : product
        )
      );
    }

  };

  const openEditorModal = (index, description, title) => {
    setModalContent({ index, description, title });
    setIsEditModalOpen(true);
  };

  const closeEditorModal = () => {
    setIsEditModalOpen(false);
  };

  const handleOpenModal = (index) => {
    setActiveModalId(index);
  };

  const handleCloseModal = () => {
    setActiveModalId(null);
  };

  const handleConfirmationOpen = () => {
    setconfirmationModal(true);
  }

  const handleConfirmationClose = () => {
    setconfirmationModal(false);
  }

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;

    if (name === 'price') {
      let { price, percentage } = calculatePriceAndPercentage(value, editedProducts[index].price, editedProducts[index].ebay_sync_data.priceType)
      setEditedProducts((prevProducts) => {
        const updatedProducts = [...prevProducts];
        updatedProducts[index] = {
          ...updatedProducts[index],
          ebay_sync_data: {
            ...updatedProducts[index].ebay_sync_data,
            percentage, price
          }
        };
        return updatedProducts;
      });
    } else if (name === 'quantity' && value > editedProducts[index].quantity) {
      setEditedProducts((prevProducts) => {
        const updatedProducts = [...prevProducts];
        updatedProducts[index] = {
          ...updatedProducts[index],
          ebay_sync_data: {
            ...updatedProducts[index].ebay_sync_data,
            quantity: editedProducts[index].quantity
          }
        };
        return updatedProducts;
      });
    }
    else {
      setEditedProducts((prevProducts) => {
        const updatedProducts = [...prevProducts];
        updatedProducts[index] = {
          ...updatedProducts[index],
          ebay_sync_data: {
            ...updatedProducts[index].ebay_sync_data,
            [name]: value
          }
        };
        return updatedProducts;
      });
    }

  };


  const handleEditorSubmit = (value) => {
    const { index } = modalContent;
    setEditedProducts((prevProducts) => {
      const updatedProducts = [...prevProducts];
      updatedProducts[index] = {
        ...updatedProducts[index],
        ebay_sync_data: {
          ...updatedProducts[index].ebay_sync_data,
          description: value,
        },
      };
      return updatedProducts;
    });
  };

  const handleCategoryChange = async (index, categoryId) => {
    const response = await api.get(`/ebay/category-specifics/${categoryId}`);
    const aspects = response.data.requiredAspects.reduce((acc, item) => {
      acc[item.name] = ['N/A'];
      return acc;
    }, {});
    setEditedProducts((prevProducts) => {
      const updatedProducts = [...prevProducts];
      updatedProducts[index] = {
        ...updatedProducts[index],
        category: categoryId,
        ebay_sync_data: { ...updatedProducts[index].ebay_sync_data, category: categoryId, aspects: aspects }
      };
      return updatedProducts;
    });
  };

  const handleSecondaryCategoryChange = async (index, categoryId) => {
    const response = await api.get(`/ebay/category-specifics/${categoryId}`);
    const aspects = response.data.requiredAspects.reduce((acc, item) => {
      acc[item.name] = ['N/A'];
      return acc;
    }, {});
    setEditedProducts((prevProducts) => {
      const updatedProducts = [...prevProducts];
      updatedProducts[index] = {
        ...updatedProducts[index],
        secondary_category: categoryId,
        ebay_sync_data: { ...updatedProducts[index].ebay_sync_data, secondary_category: categoryId, aspects: aspects }
      };
      return updatedProducts;
    });
  };
  const handleCombinationChange = (productIndex, combinationIndex, event) => {
    const { name, value } = event.target;

    setEditedProducts((prevProducts) =>
      prevProducts.map((product, i) =>
        i === productIndex
          ? {
            ...product,
            ebay_sync_data: {
              ...product.ebay_sync_data,
              combinations: product.ebay_sync_data.combinations.map((combination, j) => {
                if (editedProducts[productIndex].combinations[combinationIndex].quantity < value) {
                  return j === combinationIndex
                    ? {
                      ...combination,
                      ebay_sync_data: {
                        ...combination.ebay_sync_data,
                        quantity: editedProducts[productIndex].combinations[combinationIndex].quantity, // Update the specific field within ebay_sync_data
                      },
                    }
                    : combination
                }
                else {
                  return j === combinationIndex
                    ? {
                      ...combination,
                      ebay_sync_data: {
                        ...combination.ebay_sync_data,
                        [name]: value, // Update the specific field within ebay_sync_data
                      },
                    }
                    : combination
                }


              }),
            },
          }
          : product
      )
    );
  };

  const validateForm = () => {

    return editedProducts.every(product => {
      if (product.ebay_sync_data.is_sync) {
        return (product.ebay_sync_data.quantity &&
          product.ebay_sync_data.price &&
          product.ebay_sync_data.description &&
          product.ebay_sync_data.category)
      } else {
        return true;
      }
    }
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      showNotification('Please fill in all required fields.', 'error');
      return;
    }

    try {
      setLoading(true);
      const updatedProducts = editedProducts.map((product, index) => {
        return ({
          ...product,
          is_sync: product.is_sync,
          name: editedProducts[index] || product.name,
          ebay_sync_data: { ...product.ebay_sync_data, is_sync: product.ebay_sync_data.is_sync }
        })
      });

      let anySync = false;
      for (let ep of editedProducts) {
        if (ep.is_sync || ep.ebay_sync_data.is_sync) {
          anySync = true;
        }
      }

      await api.post('/ebay/product', { productData: updatedProducts });
      showNotification(`Product(s) data has been ${anySync ? 'synced and' : ''} saved successfully!`, 'success');
      setLoading(false);

      handleClose();
    } catch (error) {
      setLoading(false);
      showNotification(error.message ?? `There was an error updating the product!.`, 'error');
      console.error("There was an error updating the products!", error);
    }

    handleConfirmationClose();
  };

  const handleIsSync = (event) => {
    event.preventDefault();
    if (!validateForm()) {
      showNotification('Please fill in all required fields.', 'error');
      return;
    }

    const isSyncEnabled = editedProducts.some((product, index) => {
      const toggleKey = `${index}-is_sync`;
      return toggleState[toggleKey];
    });

    isSyncEnabled ? handleConfirmationOpen() : handleSubmit(event);
  }

  const truncateText = (text, wordLimit, isExpanded) => {
    const words = text.split(/\s+/);
    if (isExpanded || words.length <= wordLimit) {
      return text;
    }
    return words.slice(0, wordLimit).join(' ') + '...';
  };

  const wordLimit = 7;

  const handleDeleteProduct = (productIndex) => {
    const updatedProducts = [...editedProducts];
    updatedProducts.splice(productIndex, 1);
    setEditedProducts(updatedProducts);
    if (updatedProducts.length === 0) {
      handleClose();
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth
        sx={{
          "& .MuiPaper-root": {
            maxWidth: "100%",
            padding: "20px 24px",
          }
        }}
      >
        <DialogTitle className={styles.modalMainHeading}>Selected Products</DialogTitle>
        <DialogContent className={styles['custom-dialog-content']}>
          <form id="product-form">
            <TableContainer>
              <Table className={styles.multiSelectTable}>
                <TableHead>
                  <TableRow>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {editedProducts.map((product, productIndex) => (
                    <React.Fragment key={product.id}>
                      <TableRow
                        style={{
                          backgroundColor: product.combinations?.length > 0 ? '#ebeef0' : 'white',
                          borderBottom: '1px solid #ebeef0'
                        }}>
                        <TableCell className={`${styles['custom-table-cell']} ${styles.multiSelectImage}`}>
                          <div className={styles.titleContainer}>
                            <InputLabel className={styles.ecwidLabels}>
                              SKU:
                            </InputLabel>
                            {product.sku ?? 'N/A'}
                          </div>
                          <img src={product.thumbnailUrl ?? noImage} alt={product.name} width="50" height="50" />
                        </TableCell>

                        <TableCell className={`${styles.multiSelectTitles}`}>
                          <div className={styles.titleContainer}>
                            <InputLabel className={styles.ecwidLabels}>
                              <span className={styles.textRed}>*</span> Product Title:
                            </InputLabel>
                            {product.name ?? 'N/A'}
                          </div>

                          <CustomSwitch
                            checked={toggleState[`${productIndex}-name`] || false}
                            onChange={() => handleToggleChange(productIndex, 'name')}
                            label="Alternate Title"
                          />
                          {toggleState[`${productIndex}-name`] && (
                            <div className={styles.infoIconField}>
                              {(editedProducts[productIndex]?.ebay_sync_data.name || ``).length > tooltipThreshold && (
                                <Tooltip title={editedProducts[productIndex]?.ebay_sync_data.name || ``} arrow>
                                  <IconButton>
                                    <InfoIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                              <TextField
                                style={{ width: "100%" }}
                                className={styles['custom-text-field-title']}
                                value={editedProducts[productIndex]?.ebay_sync_data.name || ''}
                                onChange={(e) => handleInputChange(productIndex, e)}
                                placeholder="Enter product name"
                                name="name"
                                inputProps={{ maxLength: 80 }}
                              />
                            </div>
                          )}
                        </TableCell>

                        <TableCell className={`${styles.multiSelectPrice}`}>
                          <div className={styles.titleContainer}>
                            <InputLabel className={styles.ecwidLabels}>
                              <span className={styles.textRed}>*</span> Product Price:
                            </InputLabel>
                            {product.defaultDisplayedPriceFormatted}
                          </div>
                          <CustomSwitch
                            checked={toggleState[`${productIndex}-price`] || false}
                            onChange={() => handleToggleChange(productIndex, 'price')}
                            label="Ebay's Price"
                          />
                          {toggleState[`${productIndex}-price`] && (
                            <>
                              <TextField
                                className={styles['custom-text-field']}
                                name="price"
                                value={product.ebay_sync_data.priceType === 'normal' ? product.ebay_sync_data.price : product.ebay_sync_data.percentage}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  if (priceRegex(value)) {
                                    handleInputChange(productIndex, e)
                                  }
                                }}
                                placeholder="Enter product price"
                              />
                              <div className={styles['radio-group-container']}>
                                {<PriceTypeSelector
                                  value={product.ebay_sync_data.priceType}
                                  onChange={(e) => {
                                    handleInputChange(productIndex, e);
                                  }}
                                />}
                              </div>
                            </>
                          )}
                        </TableCell>
                        <TableCell className={`${styles['custom-table-cell']} ${styles.multiSelectQuantity}`}>
                          <div className={styles.titleContainer}>
                            <InputLabel className={styles.ecwidLabels}>
                              <span className={styles.textRed}>*</span> Product Quantity:
                            </InputLabel>
                            {product.quantity}
                          </div>

                          <CustomSwitch
                            checked={toggleState[`${productIndex}-quantity`] || false}
                            onChange={() => handleToggleChange(productIndex, 'quantity')}
                            label="Alternate Quantity"
                          />

                          {toggleState[`${productIndex}-quantity`] && (
                            <TextField
                              className={styles['custom-text-field']}
                              name="quantity"
                              value={product.ebay_sync_data.quantity}
                              onChange={(e) => {
                                const { value } = e.target;
                                if (priceRegex(value)) {
                                  handleInputChange(productIndex, e)
                                }
                              }}
                              placeholder="Qty"
                            />
                          )}
                        </TableCell>

                        <TableCell className={`${styles['custom-table-cell']} ${styles.multiIsSync}`}>
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Button variant="contained" className={styles.deleteCombination} onClick={() => handleDeleteProduct(productIndex)} style={{ minWidth: '36px' }}>
                              <ClearIcon />
                            </Button>

                            <CustomSwitch
                              checked={toggleState[`${productIndex}-is_sync`]}
                              onChange={() => handleToggleChange(productIndex, 'is_sync')}
                              label=""
                              sx={{ padding: 0, margin: '0' }}  // Adjust Switch padding/margin
                            />

                            <InputLabel style={{ fontSize: '12px', textAlign: 'center', marginTop: '0px' }}>
                              {product.ebay_sync_data.is_sync ? <span style={{ color: 'green' }}>Synced</span> : <span style={{ color: 'red' }}>Not Synced</span>}
                            </InputLabel>
                          </div>
                        </TableCell>
                      </TableRow>

                      <TableRow style={{
                        backgroundColor: product.combinations?.length > 0 ? '#ebeef0' : 'white'
                      }}>
                        <TableCell className={`${styles.multiSelectCategory}`}>
                          <InputLabel className={styles.topZero}><span className={styles.textRed}>* </span>Primary Category</InputLabel>
                          <CategorySelection
                            selectedCategory={product.ebay_sync_data.category}
                            onCategoryChange={(categoryId) => handleCategoryChange(productIndex, categoryId)}
                            className={styles.modalMultiSelectbox}
                            isPrimaryCategory={product.ebay_sync_data?.secondary_category}
                          />

                        </TableCell>
                        <TableCell className={`${styles.multiSelectCategory}`}>
                          <InputLabel className={styles.topZero}>Secondary Category</InputLabel>
                          <CategorySelection
                            selectedCategory={product.ebay_sync_data?.secondary_category}
                            onCategoryChange={(categoryId) => handleSecondaryCategoryChange(productIndex, categoryId)}
                            className={styles.modalMultiSelectbox}
                            isPrimaryCategory={product?.ebay_sync_data?.category}
                          />

                        </TableCell>

                        <TableCell className={` ${styles.multiSelectDesc}`}>
                          <div className={styles.titleContainer}>
                            <InputLabel className={styles.ecwidLabels}>
                              <span className={styles.textRed}>*</span> Product Description:
                            </InputLabel>
                          </div>
                          <div
                            className={`${styles['custom-description-cell-content']} ${styles.ecwidDesc}`}
                            dangerouslySetInnerHTML={{ __html: truncateText(product.description ?? 'N/A', wordLimit, descriptionExpanded[productIndex]) }}
                          />
                          <div className={styles.multiActionBtns}>
                            {product.description.split(/\s+/).length > wordLimit && (
                              <Button onClick={() => handleOpenModal(productIndex)} className={styles.viewFullDesc}>
                                View Full Description
                              </Button>
                            )}

                            <CustomSwitch
                              checked={toggleState[`${productIndex}-description`] || false}
                              onChange={() => handleToggleChange(productIndex, 'description')}
                              label="Ebay's Description"
                            />
                            {toggleState[`${productIndex}-description`] && <Button onClick={() => openEditorModal(productIndex, product.ebay_sync_data.description, product.ebay_sync_data.name)} variant="contained" color="primary">Description for Ebay</Button>}
                          </div>

                          {activeModalId === productIndex && (
                            <Modal
                              modalOpen={true}
                              modalHandleClose={handleCloseModal}
                              title={product.name}
                            >
                              <div
                                className={`${styles['custom-description-cell-content']} ${styles.modalProductDesc}`}
                                dangerouslySetInnerHTML={{ __html: product.description ?? 'N/A' }}
                              />

                              <div className={styles.closeModalBtn} onClick={handleCloseModal}><ClearIcon /></div>
                            </Modal>
                          )}
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>

                      <TableRow style={{
                        backgroundColor: product.combinations?.length > 0 ? '#ebeef0' : 'white'
                      }}>
                        <TableCell className={`${styles['custom-table-cell']} ${styles.multiSelectShipping}`}>
                          <div className={styles.titleContainer}>
                            <InputLabel className={styles.ecwidLabels}>
                              Shipping Cost:
                            </InputLabel>
                            <div className={styles.lightText}>
                              {product.ebay_sync_data.shippingCost ? `$${product.ebay_sync_data.shippingCost}` : 'Not set'}
                            </div>
                          </div>

                          <CustomSwitch
                            checked={toggleState[`${productIndex}-shippingCost`] || false}
                            onChange={() => handleToggleChange(productIndex, 'shippingCost')}
                            label="Set Shipping Cost"
                          />

                          {toggleState[`${productIndex}-shippingCost`] && (
                            <TextField
                              fullWidth
                              name="shippingCost"
                              value={product.ebay_sync_data.shippingCost}
                              onChange={(e) => {
                                if (priceRegex(e.target.value)) {
                                  handleInputChange(productIndex, e);
                                }
                              }}
                              placeholder="Enter shipping cost (e.g. 1.00)"
                              className={styles.fieldStyles}
                              inputProps={{ maxLength: 6 }}
                            />
                          )}
                        </TableCell>

                        <TableCell className={`${styles['custom-table-cell']} ${styles.multiSelectShipping}`}>
                          <div className={styles.titleContainer}>
                            <InputLabel className={styles.ecwidLabels}>
                              Additional Shipping Cost:
                            </InputLabel>
                            <div className={styles.lightText}>
                              {product.ebay_sync_data.additionalShippingCost ? `$${product.ebay_sync_data.additionalShippingCost}` : 'Not set'}
                            </div>
                            <div className={styles.fieldHint}>For each additional item</div>
                          </div>

                          <CustomSwitch
                            checked={toggleState[`${productIndex}-additionalShippingCost`] || false}
                            onChange={() => handleToggleChange(productIndex, 'additionalShippingCost')}
                            label="Set Additional Cost"
                          />

                          {toggleState[`${productIndex}-additionalShippingCost`] && (
                            <TextField
                              fullWidth
                              name="additionalShippingCost"
                              value={product.ebay_sync_data.additionalShippingCost}
                              onChange={(e) => {
                                if (priceRegex(e.target.value)) {
                                  handleInputChange(productIndex, e);
                                }
                              }}
                              placeholder="Enter additional shipping cost (e.g. 1.00)"
                              className={styles.fieldStyles}
                              inputProps={{ maxLength: 6 }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                      {product.ebay_sync_data.combinations && product.ebay_sync_data.combinations.map((combination, combinationIndex) => (
                        <TableRow key={combination.id} style={{ backgroundColor: 'rgba(235, 238, 240, 0.38)' }} className={styles.variableChildProduct}>
                          <TableCell className={`${styles['custom-table-cell']} ${styles.multiSelectImage}`}>
                            <div className={styles.titleContainer}>
                              <InputLabel className={styles.ecwidLabels}>
                                SKU:
                              </InputLabel>
                              {combination.sku ?? 'N/A'}
                            </div>
                            {combination.thumbnailUrl ? (
                              <img src={combination.thumbnailUrl} alt={combination.ebay_sync_data.name} width="50" height="50" />
                            ) : (
                              <img src={noImage} alt={combination.name} width="50" height="50" />
                            )}
                          </TableCell>

                          <TableCell className={`${styles.multiSelectTitles}`}>
                            <div className={styles.titleContainer}>
                              <InputLabel className={styles.ecwidLabels}>
                                <span className={styles.textRed}>*</span> Variant Title:
                              </InputLabel>
                              {combination.name ?? 'N/A'}
                            </div>

                            <CustomSwitch
                              checked={toggleState[`${productIndex}-${combinationIndex}-name`] || false}
                              onChange={(e) => handleToggleCombinationChange(productIndex, combinationIndex, e)}
                              label="Alternate Title"
                              name="name"
                            />
                            {toggleState[`${productIndex}-${combinationIndex}-name`] && (
                              <div className={styles.infoIconField}>
                                {(combination.ebay_sync_data.name || ``).length > tooltipThreshold && (
                                  <Tooltip title={combination.ebay_sync_data.name || ``} arrow>
                                    <IconButton>
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <TextField
                                  style={{ width: "100%" }}
                                  className={styles['custom-text-field-title']}
                                  value={`${combination.ebay_sync_data.name} `}
                                  onChange={(e) => handleCombinationChange(productIndex, combinationIndex, e)}
                                  placeholder="Enter Variant name"
                                  name="name"
                                  inputProps={{ maxLength: 80 }}
                                />
                              </div>
                            )}
                          </TableCell>

                          <TableCell className={`${styles['custom-table-cell']} ${styles.multiSelectQuantity}`}>
                            <div className={styles.titleContainer}>
                              <InputLabel className={styles.ecwidLabels}>
                                <span className={styles.textRed}>*</span> Variant Quantity:
                              </InputLabel>
                              {combination.quantity}
                            </div>

                            <CustomSwitch
                              checked={toggleState[`${productIndex}-${combinationIndex}-quantity`]}
                              onChange={(e) => handleToggleCombinationChange(productIndex, combinationIndex, e)}
                              label="Alternate Quantity"
                              name="quantity"
                            />

                            {toggleState[`${productIndex}-${combinationIndex}-quantity`] && (
                              <TextField
                                className={styles['custom-text-field']}
                                name="quantity"
                                value={combination.ebay_sync_data.quantity}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  if (priceRegex(value)) {
                                    handleCombinationChange(productIndex, combinationIndex, e)
                                  }
                                }}
                                placeholder="Qty"
                              />
                            )}


                          </TableCell>

                          {/* Price Field */}
                          <TableCell className={`${styles.multiSelectPrice}`}>
                            <div className={styles.titleContainer}>
                              <InputLabel className={styles.ecwidLabels}>
                                <span className={styles.textRed}>*</span> Variant Price:
                              </InputLabel>
                              {combination.defaultDisplayedPriceFormatted}
                            </div>


                            <TextField
                              className={styles['custom-text-field']}
                              name="price"
                              value={product.ebay_sync_data.price}
                              disabled={true}
                              placeholder="Enter Variant price"
                            />

                          </TableCell>

                          <TableCell className={`${styles['custom-table-cell']} ${styles.multiIsSync}`}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <CustomSwitch
                                checked={toggleState[`${productIndex}-${combinationIndex}-is_sync`]}
                                onChange={(e) => handleToggleCombinationChange(productIndex, combinationIndex, e)}
                                label=""
                                sx={{ padding: 0, margin: '0' }}
                                name="is_sync"
                              />

                              <InputLabel style={{ fontSize: '12px', textAlign: 'center', marginTop: '0px' }}>
                                {combination.ebay_sync_data.is_sync ? <span style={{ color: 'green' }}>Synced</span> : <span style={{ color: 'red' }}>Not Synced</span>}
                              </InputLabel>
                            </div>
                          </TableCell>

                        </TableRow>
                      ))}

                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </form>
          <div className={styles.closeModalBtn} onClick={handleClose}><ClearIcon /></div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className={styles.cancelBtns} onClick={handleClose}>Close</Button>
          <Button variant="contained" color="primary" onClick={handleIsSync}>Save</Button>
        </DialogActions>
      </Dialog>

      {/** Confirmation Modal */}
      <Modal
        modalOpen={confirmationModal}
        modalHandleClose={handleConfirmationClose}
        title={
          <div className={styles.modalIconTitle}>
            <ErrorOutlineIcon />
            Are you sure?
          </div>
        }
        sx={{
          "& .MuiDialogContent-root": {
            height: "80%",
          }
        }}
        loading={loading}
      >
        <DialogContent className={styles.confirmationContent}>
          <p className={styles.textCenter}>Are you sure you want to sync the selected product(s)?</p>
        </DialogContent>
        <DialogActions className={styles.confirmationBtns}>
          <Button variant="contained" className={styles.cancelBtns} onClick={handleConfirmationClose}>No</Button>

          <div style={{ position: 'relative', display: 'inline-block' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={loading} // Disable the button while loading
            >
              Yes
            </Button>

            {loading && (
              <CircularProgress
                size={24}
                className="circular-progress-button"
              />
            )}
          </div>
        </DialogActions>
      </Modal>

      <RichTextEditorModal
        open={isEditModalOpen}
        onClose={closeEditorModal}
        initialValue={modalContent.description}
        onSubmit={handleEditorSubmit}
        title={modalContent.title || 'Fallback Title'}
      />
    </>

  );
};

export default MultiSelectDialog;
