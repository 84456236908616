import React, { useState, useEffect } from 'react';
import {
  FormControl, Select, MenuItem, IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import api from '../../utils/api';
import styles from "./categorySelect.module.css";
import { useNotification } from '../../contexts/NotificationContext';

const UniCategorySelection = ({ updatedCategory, setUpdatedCategory, isPrimaryCategory, clearSelectedCategory }) => {
  const [categories, setCategories] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { showNotification } = useNotification();

  useEffect(() => {
    fetchCategories(0); // Fetch parent categories on mount
  }, []);

  // Fetch categories based on the parentId
  const fetchCategories = async (parentId) => {
    try {
      setCategories([]);
      let cats = [];

      // Show "Select Category" option as the first item for parent category
      if (parentId === 0 || !parentId) {
        cats = [{
          category_id: 0,
          category_name: "Select Category",
          have_child: false
        }];
      }

      // Fetch data for the updatedCategory
      if (updatedCategory) {
        let category = await api.get(`/ebay/category/${updatedCategory}`);
        cats = category.data;
      }

      // Fetch the categories of the parent category
      const response = await api.get(`/ebay/categories/${parentId}`);
      let mergeArray = [...cats, ...response.data];
      setCategories(mergeArray);
    } catch (error) {
      console.error("Error fetching categories", error);
    }
  };

  // Handle category change
  const handleCategoryChange = (event) => {
    const selectedCatId = event.target.value;
    if (selectedCatId === isPrimaryCategory) {
      showNotification('Please select a different category', 'error');
      return false;
    }
    setUpdatedCategory(selectedCatId);
    setDropdownOpen(false); // Close the dropdown after selection
  };

  // Clear the selection and reset to initial state
  const clearSelection = async () => {
    setUpdatedCategory(0);
    fetchCategories(0); // Reload parent categories
    clearSelectedCategory();
  };

  // Handle the expansion of categories with children
  const handleCategoryExpansion = (categoryId) => {
    fetchCategories(categoryId); // Fetch the child categories
  };

  // Find the category name based on the updatedCategory id
  const getCategoryName = (categoryId) => {
    const selectedCategory = categories.find(cat => cat.category_id === categoryId);
    return selectedCategory ? selectedCategory.category_name : 'Select Category';
  };

  // Render the category options with their child categories if expanded
  const renderCategories = (categories) => {
    return categories.map(cat => (
      <React.Fragment key={cat.category_id}>
        <MenuItem
          value={cat.category_id}
          onClick={(e) => {
            if (cat.have_child) {
              e.stopPropagation(); // Prevent closing dropdown when clicking a category with children
              handleCategoryExpansion(cat.category_id); // Fetch and expand the child categories
            } else {
              setUpdatedCategory(cat.category_id); // Select the category if no children
              setDropdownOpen(false); // Close dropdown after selection
            }
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {/* Parent category style */}
            <span className={styles.categoryText}>
              {cat.category_name}
            </span>
            {/* If category has children, show ExpandMoreIcon */}
            {cat.have_child && (
              <IconButton
                size="small"
                className={styles.expandIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  handleCategoryExpansion(cat.category_id);
                }}
              >
                <ExpandMoreIcon />
              </IconButton>
            )}
          </div>
        </MenuItem>

        {/* If the category has children, render them below the parent with indent */}
        {cat.have_child && (
          <div style={{ paddingLeft: '20px', display: 'flex' }}>
            {cat.children?.map(childCat => (
              <MenuItem
                key={childCat.category_id}
                value={childCat.category_id}
                onClick={(e) => {
                  e.stopPropagation();
                  setUpdatedCategory(childCat.category_id); // Select the child category
                  setDropdownOpen(false); // Close dropdown after selection
                }}
              >
                <span className={styles.categoryText}>{childCat.category_name}</span>
              </MenuItem>
            ))}
          </div>
        )}
      </React.Fragment>
    ));
  };

  return (
    <>
      <FormControl fullWidth>
        <Select
          value={updatedCategory ?? 0}
          onChange={handleCategoryChange}
          open={dropdownOpen}
          onClose={() => setDropdownOpen(false)}
          onOpen={() => setDropdownOpen(true)}
          className={styles.multiSelectStyles}
        >
          {/* Display the category name */}
          <MenuItem value={updatedCategory ?? 0} disabled>
            {getCategoryName(updatedCategory ?? 0)}
          </MenuItem>

          {renderCategories(categories)}
        </Select>
        {updatedCategory && (
          <IconButton onClick={clearSelection} size="small" className={styles.categoryClearIcon}>
            <ClearIcon />
          </IconButton>
        )}
      </FormControl>
    </>
  );
};

export default UniCategorySelection;
